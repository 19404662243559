import React from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import StyledH2 from "../../components/H2";
import StyledIcon from "../../components/Icon";

import Section from "../../components/Section";
import { ThemeColors } from "../../layout/Theme";

import {experience} from '../../constants/experience';

const StyledContainer = styled(Container)`
  h2 {
    padding: 1rem 0;
  }

  ul {
    text-align: left;
    list-style-type: none;
    padding: 1rem;

    li {
      font-size: 1.2rem;
      margin: 0.5rem;
    }
  }
`;

interface IProps {
  color?: string;
  backgroundColor?: string;
}

export default function Tech(props: IProps): React.ReactElement {
  const icon = <StyledIcon color={ThemeColors.turcus} />;
  return (
    <Section color={props.color} backgroundColor={props.backgroundColor}>
      <div id="technologies">
        <StyledContainer>
          <StyledH2 color={props.color}>Technologies and Frameworks</StyledH2>
          <div>
              <ul>
                {experience.map(i => <li>{icon}{i}</li>)}
              </ul>
          </div>
        </StyledContainer>
      </div>
    </Section>
  );
}
