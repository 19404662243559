import React from 'react'
import styled from 'styled-components';
import { ThemeColors } from '../layout/Theme';

const StyledHeader = styled.header`
    background-color: ${ThemeColors.primary};
    border-bottom: 1px solid ${ThemeColors.secondary};
    position: sticky;
    top: 0;
    max-height: 50px;
    height: 50px;
    z-index: 10;
`;

const StyledWrapper = styled.div`
    max-width: 1200px;
    min-width: 320px;
    max-height: inherit;
    margin: 0 auto 0px;
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled.a`
    align-self: center;
    padding: 10px 20px;
    text-decoration: none;
    color: ${ThemeColors.secondary};

    :hover {
        text-decoration: none;
        color: ${ThemeColors.secondary};
        opacity: .8;
    } 
`;
const StyledNav = styled.nav`
    max-height: inherit;

    ul{
        max-height: inherit;
        margin-block-start: 0;
        margin-bottom: 0;
        padding-inline-start: 0;
        display: flex;
        list-style-type: none;
        height: inherit;
        padding: 10px;
        
        li{
            min-width: 40px;
            padding:  0 10px;

            a{
                text-decoration: none;
                color: ${ThemeColors.secondary};
                transition: all 2s ease 2s;

                :hover {
                    opacity: .8;
                    color: ${ThemeColors.secondary};
                }
            }
        }
    }
`;

 const Nav =() =>  {
    return (
        <StyledHeader>
            <StyledWrapper>
                <StyledLogo href="/">Home</StyledLogo>
                <StyledNav>
                    <ul>
                        <li><a href="/#technologies">Technologies</a></li>
                        <li><a href="/#projects">Projects</a></li>
                    </ul>
                </StyledNav>
            </StyledWrapper>
        </StyledHeader>
    )
}

export default Nav