import styled from "styled-components";

const StyledContainer = styled.div`
    width: 90%;
    margin: 0 auto;
    max-width: 65em;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

export default StyledContainer;