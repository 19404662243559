import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.section`
  background-color: black;
  color: white;
  padding: 0.3rem 0;
  max-height: 35px;
`;

const StyledFooterCopyright = styled.div`
  text-align: center;
  max-height: inherit;
`;

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <StyledWrapper>
      <StyledFooterCopyright>
        {" "}
        Copyright Michał Nowak {year}
      </StyledFooterCopyright>
    </StyledWrapper>
  );
}
