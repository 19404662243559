import React from "react";
import styled from "styled-components";

import { projects} from '../../constants/projects'

import Container from "../../components/Container";
import StyledIcon from "../../components/Icon";
import StyledH2 from "../../components/H2";
import StyledH4 from "../../components/H4";
import { Theme, ThemeColors } from "../../layout/Theme";
import Section from "../../components/Section";

const StyledContainer = styled(Container)`
  ul {
    text-align: left;
    list-style-type: none;

    li {
      font-size: 1.2rem;
      margin: 0.5rem;
    }
  }
  ul {
    padding: 1rem;
  }
`;

const CommercialProjectStyled = styled.div`
  h4,
  p {
    margin-bottom: 1.5rem;
  }

  :not(:last-child) {
    border-bottom: 1px solid white;
    margin-bottom: 40px;
  }
`;

const TechnologySectionStyled = styled.div`
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const TechnologyItemStyled = styled.div`
  display: inline-block;
  padding: 2px 20px;
  color: ${ThemeColors.primary};
  background-color: ${ThemeColors.secondary};
  border-radius: 10px;
  border-color: ${ThemeColors.primary};
  cursor: default;
  text-align: center;
  min-width: 100px;
`;

interface IProps {
  color?: string;
  backgroundColor?: string;
}

interface IProject {
  companyName: string;
  startYear: string;
  endYear: string;
  technologies: string[];
  shortDescription: string;
  companyProjects: string[];
  responsibilities: string[];
}

const renderProject = ({
  companyName,
  startYear,
  endYear,
  shortDescription,
  technologies,
  companyProjects,
  responsibilities,
}: IProject) => {
  const icon = <StyledIcon color={ThemeColors.turcus} />;
  return (
    <CommercialProjectStyled key={companyName}>
      <StyledH4>{companyName}</StyledH4>
      <p>
        {startYear} - {endYear}
      </p>
      <p>{shortDescription}</p>
      <TechnologySectionStyled>
        {technologies.map((t) => (
          <TechnologyItemStyled key={t}>{t}</TechnologyItemStyled>
        ))}
      </TechnologySectionStyled>
      <ul>
        {companyProjects.map((c) => (
          <li key={c}>
            <>
              {icon}
              {c}
            </>
          </li>
        ))}
      </ul>
      <h5>My responsibilities:</h5>
      <ul>
        {responsibilities.map((responsibility) => (
          <li key={responsibility}>
            <>
              {icon}
              {responsibility}
            </>
          </li>
        ))}
      </ul>
    </CommercialProjectStyled>
  );
};
const CommercialProjects: React.FC<IProps> = (props) => {
  return (
    <Section
      color={props.color}
      backgroundColor={props.backgroundColor}
      id="projects"
    >
      <StyledContainer>
        <StyledH2 theme={Theme.white}>Commercial Projects</StyledH2>

        {projects.map((project) => renderProject(project))}
      </StyledContainer>
    </Section>
  );
};

export default CommercialProjects;
