import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Button from '../components/Button';

import bgIMage from "../images/background.jpg";

const StyledWrapper = styled.section`
    min-height: calc(100vh - 85px);
    background-image: url(${bgIMage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30vh 0 0 0;
    color: white;
    text-align: center;

    button{
        margin: 1rem 0;
        padding: .5rem 0;
    }
    
`;

export default function NotFound() {
    return (
        <StyledWrapper>
            <Container>
                <h1>404</h1>
                <h2>Nothing found</h2>
                <a href="/"><Button>Go back to home page</Button></a>
            </Container>
        </StyledWrapper>
    )
}
