import styled from "styled-components";

const StyledButton = styled.button`
    margin: .5rem 0;
    width: 80%;
    cursor: unset !important;
    border: .06rem solid grey;
    background-color: #66cb66;
    padding: 0.2rem .5rem;
    font-weight: 500;
    transition: .3s all ease-in-out ;

    :hover{
        opacity: .9;
        border-color: white;
    }
    
`;

export default StyledButton;