import React from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../icons/check-solid.svg";

const StyledIcon = styled(CheckIcon)`
  height: 10px;
  padding-right: 10px;
  color: ${(props) => props.color};
`;

interface IProps {
  color?: string;
  backgroundColor?: string;
}

const Icon = (props: IProps): React.ReactElement => (
  <StyledIcon color={props.color} />
);

export default Icon;
