import React from "react";
import LinkedInSVG from "../images/linkedIn.svg";
import GitHubSVG from "../images/github.svg";
import styled from "styled-components";
import { ThemeColors } from "../layout/Theme";

enum FloatingImageEnum {
  linkedIn,
  github,
}

const StyledA = styled.a`
  text-decoration: none;
  width: fit-content;
`;

const ButtonsSectionWrapper = styled.div`
  width: 57px;
  position: fixed;
  height: 106px;
  top: 50%;
  transform: translateY(-50%);
  background: ${ThemeColors.secondary};
  padding: 0.5rem 0;
  border-radius: 10px;
  @media (max-width: 600px) {
    opacity: 0.5;
  }
`;

const getIcon = (iconName: FloatingImageEnum) => {
  switch (iconName) {
    case FloatingImageEnum.linkedIn:
      return LinkedInSVG;
    case FloatingImageEnum.github:
      return GitHubSVG;
  }
};

const ProjectPhoto = styled.div`
  max-width: 80%;
  padding: 3px;
  margin: auto;
`;

interface IFloatingButtonProps {
  name: string;
  title: string;
  url: string;
  iconName: FloatingImageEnum;
}
const buttons: IFloatingButtonProps[] = [
  {
    name: "linkedIn",
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/michał-nowak-06029014a/",
    iconName: FloatingImageEnum.linkedIn,
  },
  {
    name: "github",
    title: "GitHub",
    url: "https://github.com/michalnowakdev",
    iconName: FloatingImageEnum.github,
  },
];

export const FloatingButtons: React.FC<{}> = () => {
  return (
    <ButtonsSectionWrapper>
      {buttons.map(({ url, iconName, name }) => (
        <StyledA href={url ? url : "/contact"} key={name}>
          <ProjectPhoto>
            <img
              src={getIcon(iconName)}
              alt={name}
              width={"40px"}
              height={"auto"}
            />
          </ProjectPhoto>
        </StyledA>
      ))}
    </ButtonsSectionWrapper>
  );
};
