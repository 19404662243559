import AuthenticationService from "../Services/AuthenticationService";

const initialState = {
    isAdmin: AuthenticationService.isAuthenticated()
}

export const mainReducer = function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case "SET_LOGGED_IN":
            const newState = { ...state };
            newState.isAdmin = payload;
            return newState;
        default:
            return state;
    }
};