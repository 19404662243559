import React from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import Container from '../../components/Container';
import StyledH2 from '../../components/H2';

import FoodGuide from '../../images/FoodGuide.png';
import ReceiptCollector from '../../images/ReceiptCollector.png';
import { Theme } from '../../layout/Theme';

const StyledWrapper = styled.section`
    min-height: calc(50vh - 50px);
    color: black;
    text-align: center;
`;

// const StyledImage = styled.img`
//     width: 95%;
//     height: auto;
//     margin: .5rem;
//     border: 1px grey solid;
// `;
// const StyledImagesWrapper = styled.section`
//     display: grid;
//     grid-template-columns: repeat(3,2fr);
// `;

const images = [
    {
        original: FoodGuide,
        thumbnail: FoodGuide,
        description: "Food guide main page"
    },
    {
        original: ReceiptCollector,
        thumbnail: ReceiptCollector,
        description: "Receipt Collector main page"
    }
]

export default function ImagesSection() {
    return (
        <StyledWrapper>
            <Container>
                <StyledH2 theme={Theme.dark}>Images</StyledH2>
                <ImageGallery items={images} />
            </Container>
        </StyledWrapper>
    )
}
