import React from "react";
import styled from "styled-components";

import StyledH2 from "../../components/H2";
import SelfieImg from "../../images/selfie.jpg";
import Container from "../../components/Container";
import Section from "../../components/Section";

const StyledWrapper = styled(Container)`
  text-align: center;

  p {
    margin: 0.5rem;
    font-size: 1.1rem;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 2fr);
  }
`;

const StyledDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;

  h2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    @media (max-width: 600px) {
      margin-top: 1.5rem;
    }
  }
  h4 {
    margin-bottom: 1rem;
  }
  p {
    margin: 0.5rem 0;
    font-weight: 500;
  }
`;

const StyledImage = styled.img`
  height: 500px;
  width: auto;
  margin: auto;
  box-shadow: 0px 0px 7px 7px lightgrey;

  @media (max-width: 600px) {
    height: 50vh;
  }
`;

interface IProps {
  color?: string;
  backgroundColor?: string;
}

export default function BioSection(props: IProps): React.ReactElement {
  return (
    <Section color={props.color} backgroundColor={props.backgroundColor}>
      <StyledWrapper>
        <StyledRow>
          <StyledImage src={SelfieImg} />
          <StyledDescription>
            <StyledH2 color={props.color}>Welcome</StyledH2>
            <h4>I'm Mike and I'm a software developer.</h4>

            <p>
              My favorite frontend framework/library is <strong>React</strong>{" "}
              where is spend most of my time.
            </p>
            <p>
              I'm also focusing on my CSS skills and clean/reusable code and
              components.
            </p>
            <p>
              I'm open to new and unconventional solutions and sharing my
              knowledge with people.
            </p>
          </StyledDescription>
        </StyledRow>
      </StyledWrapper>
    </Section>
  );
}
