import React from 'react';
import styled from 'styled-components';

import Container from '../../components/Container';
import StyledH2 from '../../components/H2';
import { Theme } from '../../layout/Theme';

const StyledWrapper = styled.section`
    min-height: calc(50vh - 50px);
    color: black;
    text-align: center;
`;

export default function DescriptionSection() {
    return (
        <StyledWrapper>
            <Container>
                <StyledH2 theme={Theme.dark}>Description</StyledH2>
                <h3>Idea</h3>
                <p>Main idea to develop this app is...</p>
                <h3>Tech stack</h3>
                <p>Main idea to develop this app is...</p>
                <h3>Challenges</h3>
                <p>Main idea to develop this app is...</p>
            </Container>
        </StyledWrapper>
    )
}
