import React from 'react';
import styled from 'styled-components';
import { Theme, ThemeColors } from '../layout/Theme';

const StyledH2 = styled.h2`
	text-align: center;
	text-transform: uppercase;
	margin: 1rem;
	color: ${props => props.color ? props.color : props.theme === Theme.dark ? ThemeColors.primary : ThemeColors.secondary};
`;


interface IProps {
	theme?: Theme,
	color?: string,
	children?: React.ReactNode

}

const H2 = (props: IProps): React.ReactElement =>
	<StyledH2
		theme={props.theme}
		color={props.color}
	>
		{props.children}
	</StyledH2>;

export default H2;


