import React from 'react';
import styled from 'styled-components';

import Container from '../../components/Container';
import StyledH2 from '../../components/H2';

import bgIMage from "../../images/background.jpg";
import { Theme } from '../../layout/Theme';

const StyledWrapper = styled.section`
    height: 40vh;
    background-image: url(${bgIMage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-align: center;
`;

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    height: inherit;
`;

const StyledDescription = styled.h3`
    margin: 2rem 0;
    font-style: italic;
    font-size: 1.2rem;
`;

interface IProps {
    title: string
}

export default function Header(props: IProps) {
    const { title } = props;
    return (
        <StyledWrapper>
            <StyledContainer>
                <StyledH2 theme={Theme.white}>{title}</StyledH2>
                <StyledDescription>Innovative project for saving favourite restaurants and dishes.</StyledDescription>
            </StyledContainer>
        </StyledWrapper>
    )
}
