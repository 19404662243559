import React from "react";
import styled from "styled-components";

const StyledSection = styled.section``;

interface IProps {
  id?: string;
  color?: string;
  backgroundColor?: string;
}

const Section: React.FC<IProps> = ({
  color,
  backgroundColor,
  id,
  children,
}): React.ReactElement => (
  <StyledSection
    style={{
      color,
      backgroundColor: backgroundColor,
    }}
    id={id}
  >
    {children}
  </StyledSection>
);

export default Section;
