import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './Redux/actions';

import Nav from './components/Nav';
import Footer from './components/Footer';

import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Project from './pages/Project';

function App() {
	return (
		<Router>
			<Nav/>
			<main>
				<Switch>
					<Route path="/" exact component={(props: any) => <Main {...props} />} />
					<Route path="/projects/:id" component={(props: any) => <Project {...props} />} />
					<Route component={NotFound} />
				</Switch>
			</main>
			<Footer />
		</Router>
	);
}

const mapStateToProps = (state: any) => ({
	isAdmin: state.isAdmin
})
const mapDispatchToProps = (dispatch: any) => ({
	setLoggedIn: (value: boolean) => dispatch(actions.setLoggedIn(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
