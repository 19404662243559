import React from "react";
import styled from "styled-components";
import { Theme, ThemeColors } from "../layout/Theme";

const StyledH4 = styled.h4`
  color: ${(props) =>
    props.color
      ? props.color
      : props.theme === Theme.dark
      ? ThemeColors.primary
      : ThemeColors.secondary};
`;

interface IProps {
  theme?: Theme;
  color?: string;
  children?: React.ReactNode;
}

const H4 = (props: IProps): React.ReactElement => (
  <StyledH4 theme={props.theme} color={props.color}>
    {props.children}
  </StyledH4>
);

export default H4;
