import React from "react";
import BioSection from "../sections/main/BioSection";
import Basic from "../sections/main/BasicSection";
import Tech from "../sections/main/TechnologiesSection";
import { ThemeColors } from "../layout/Theme";
import CommertialProjects from "../sections/main/CommertialProjectsSection";
import { FloatingButtons } from "../components/FloatingButtons";

function App() {
  return (
    <>
      <FloatingButtons />
      <BioSection
        backgroundColor={ThemeColors.secondary}
        color={ThemeColors.primary}
      />
      <Basic
        backgroundColor={ThemeColors.primary}
        color={ThemeColors.secondary}
      />
      <Tech
        backgroundColor={ThemeColors.secondary}
        color={ThemeColors.primary}
      />
      <CommertialProjects
        backgroundColor={ThemeColors.primary}
        color={ThemeColors.secondary}
      />
    </>
  );
}

export default App;
