import React from 'react';
import styled from 'styled-components';

import Container from '../../components/Container';
import StyledIcon from '../../components/Icon';
import StyledH2 from '../../components/H2';
import { Theme, ThemeColors } from '../../layout/Theme';
import Section from '../../components/Section';

const StyledContainer = styled(Container)`
    ul{
        text-align: left;
        list-style-type: none;

        li{
            font-size: 1.2rem;
            margin: .5rem;
        }
    }
    ul{
        padding: 1rem;
    }
`;

interface IProps {
    color?: string,
    backgroundColor?: string
}


export default function Basic(props: IProps): React.ReactElement {

    const icon = <StyledIcon color={ThemeColors.turcus} />;
    return (
        <Section
            color={props.color}
            backgroundColor={props.backgroundColor}
        >
            <StyledContainer>
                <StyledH2 theme={Theme.white}>Day to Day stuff</StyledH2>
                <ul>
                    <li>{icon}Developing new functionalities</li>
                    <li>{icon}Developing new components</li>
                    <li>{icon}Code review</li>
                    <li>{icon}Bug fixing</li>
                </ul>
            </StyledContainer>
        </Section>
    )
}
