export default class AuthenticationService {
    public static logIn = (): void => {
        localStorage.setItem("isAuthenticated", "true")
    }

    public static logOut = (): void => {
        localStorage.setItem("isAuthenticated", "false")
    }

    public static isAuthenticated = (): boolean => {
        const isAuthenticated = localStorage.getItem("isAuthenticated");
        return AuthenticationService.getStatus(isAuthenticated ? isAuthenticated : "");
    }

    private static getStatus = (status?: string): boolean => {
        let parsed = false;
        switch (status) {
            case "true": {
                parsed = true;
                break;
            }
            default: {
                parsed = false;
            }
        }
        return parsed;
    }
}