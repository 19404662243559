export const projects = [
  {
    companyName: "ConciseSoftware International",
    startYear: "2021",
    endYear: "Current",
    technologies: ["React","React Native", "Typescript", "Redux toolkit", "ES6", "Styled Components", "Jest"],
    shortDescription: "",
    projectNames: ["TeamHero","Biostatsis"],
    companyProjects: [
      "Project 1 - (React) - Web application for employee management.",
      "Project 2 - (React Native) - Mobile application for checking health data.",
    ],
    responsibilities: [
      "Implement Figma/Zepplin designs",
      "Developing new views and reusable components",
      "Write unit test using Jest and react-testing-library",
      "Developing new functionalities",
      "Bug fixing",
      "Code review",
    ],
  },
  {
    companyName: "ABB Hitachi Power Grids",
    startYear: "2020",
    endYear: "2021",
    technologies: ["React", "Typescript", "ES6", "Styled Components", "Jest"],
    shortDescription: "",
    projectNames: ["SDM600"],
    companyProjects: [
      "Project 1 - Comprehensive software solution for automatic management of service and cyber security-relevant data across the electric substations.",
    ],
    responsibilities: [
      "Cooperation with designers",
      "Developing new views and reusable components",
      "Write unit test using Jest and react-testing-library",
      "Developing new functionalities",
      "Bug fixing",
      "Code review",
    ],
  },
  {
    companyName: "Ericsson",
    startYear: "2018",
    endYear: "2020",
    technologies: ["React", "JSX", "ES6", "LESS"],
    shortDescription: "I've been working on two projects: ",
    projectNames: ["HWS Connect", "FSM (Vanilla)"],
    companyProjects: [
      "Project 1 - Internal tool for visualization of large amount of data mostly in data grids, charts and maps.",
      "Project 2 - Internal tool developed as a substitute of FSM desktop app. I was responsible for design and development of the views and components and maintain them.",
    ],
    responsibilities: [
      "Design views",
      "Developing new views and reusable components",
      "Developing new functionalities",
      "Bug fixing",
      "Code review",
    ],
  },
];
