import React from 'react';
import Header from '../sections/project/Header';
import ImagesSection from '../sections/project/ImagesSection';
import DescriptionSection from '../sections/project/DescriptionSection';

export default function Project(props: any) {
    const id = props.match.params.id;
    return (
        <>
            <Header title={id} />
            <ImagesSection />
            <DescriptionSection />
        </>
    )
}
