export const ThemeColors = {
    primary: "#222430",
    secondary: "#E7EAE9",
    turcus: "#27A2A7",
    blue: "#5357A0",
    brown: "#5D4732"
}

export enum Theme {
    white = "white",
    dark = "dark"
}